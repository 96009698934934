import React, { useRef, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ContactSuccess from "../components/contact-success"
import SEO from "../components/seo"
import API from "../constants/api"
import ReCAPTCHA from "react-google-recaptcha"
import "../contact.css"

const ContactUs = ({ data }) => {
  const sites = data.allMysqlSites.edges
    .filter(node => node.node.is_active === "y")
    .sort((a, b) => {
      let x = a.node.site_name[0].toLowerCase()
      let y = b.node.site_name[0].toLowerCase()
      if (x < y) {
        return -1
      }
      if (x > y) {
        return 1
      }
      return 0
    })

  const recaptchaRef = useRef(null)
  const finalValues = useRef(null)
  const [msg, setMsg] = useState("")
  const [isProcessing, setIsProcessing] = useState(false)
  const [msgSent, setMsgSent] = useState(false)
  const [serverError, setServerError] = useState("")
  const [errType, setErrType] = useState("")
  const [email, setEmail] = useState("")
  const [friend, setFriend] = useState("")
  const handleSubmit = e => {
    e.preventDefault()

    const vali = validateForm()
    if (vali) {
      setIsProcessing(true)
      setErrType("")
      finalValues.current = { msg: msg, email: email, friend: friend }
      recaptchaRef.current.execute()
    }
  }
  const validateForm = () => {
    if (email === "" || !email.includes("@") || !email.includes(".")) {
      setErrType("email")
      return false
    }

    if (msg === "") {
      setErrType("msg")
      return false
    }

    if (friend === "") {
      setErrType("friend")
      return false
    }

    return true
  }
  const formSuccess = () => {
    setMsgSent(true)
  }
  const fromHasError = specialErrMsg => {
    setServerError(specialErrMsg)
  }
  const handleFormSubmit = tok => {
    let data = new FormData()
    data.append("email", finalValues.current.email)
    data.append("msg", finalValues.current.msg)
    data.append("friend", finalValues.current.friend)
    data.append("token", tok)
    fetch(`${API.SUPPORT}get_help.php`, {
      method: "POST",
      body: data,
      headers: {
        "X-XSRF-Header": API.XSRF,
      },
    })
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          formSuccess()
        } else {
          fromHasError(res.error)
        }
      })
  }
  return (
    <Layout>
      <SEO title="Contact Us" />
      <div className="section_form">
        <div className="section_background">
          <img src={"/af-bg.jpg"} alt="" />
        </div>
        <div className="block_form">
          <form action="#">
            <div className="block_form_wrapper">
              {msgSent ? (
                <ContactSuccess />
              ) : (
                <>
                  <div className="heading">
                    <h2 className="title">
                      Contact <strong>Us</strong>
                    </h2>
                    <p>
                      The more we know about why you are contacting us, the
                      better we can assist you. Please include as much detail as
                      possible! If you're a member, please include the email
                      address you signed up with, that is important. We will get
                      back to you as soon as possible. You can try and{" "}
                      <a href="/reset/">reset your account</a> and login again
                      to see if that clears up any issues.
                      {serverError !== "" ? (
                        <div className="errorServer">
                          ERROR: {serverError}, Please contact us directly{" "}
                          <a href="mailto:service@addfriends.com">
                            service@addfriends.com
                          </a>
                        </div>
                      ) : null}
                    </p>
                  </div>
                  {serverError !== "" ? null : (
                    <div className="field">
                      <div className="row">
                        <div className="wrap">
                          <label htmlFor="email" className="label">
                            Email
                          </label>
                          <input
                            type="email"
                            id="email"
                            className={
                              errType === "email" ? "input error" : "input"
                            }
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                          />
                          {errType === "email" ? (
                            <div className="errorMsg">Error</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="wrap">
                          <label htmlFor="message" className="label">
                            How can we help?
                          </label>
                          <textarea
                            name="message"
                            id="message"
                            cols="30"
                            rows="10"
                            className={
                              errType === "msg" ? "textarea error" : "textarea"
                            }
                            onChange={e => setMsg(e.target.value)}
                            value={msg}
                          ></textarea>
                          {errType === "msg" ? (
                            <div className="errorMsg msgErr">Error</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="wrap">
                          <label htmlFor="message" className="label">
                            What Friend?
                          </label>
                          <select
                            className={
                              errType === "friend"
                                ? "afSelect wError"
                                : "afSelect"
                            }
                            onChange={e => {
                              setFriend(e.target.value)
                            }}
                          >
                            <option value="">PICK ONE</option>
                            {sites.map(site => (
                              <option
                                value={site.node.site_name}
                                key={`cu${site.node.mysqlId}`}
                              >
                                {site.node.site_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          onChange={e =>
                            handleFormSubmit(e, msg, email, friend)
                          }
                          theme="dark"
                          badge="inline"
                          size="invisible"
                          sitekey="6LdFnKgUAAAAAMMrHjMOzd42QrbFzhO-aO_Rme2k"
                        />
                      </div>

                      <div className="row">
                        <button
                          className={
                            isProcessing
                              ? "button_more processing"
                              : "button_more"
                          }
                          onClick={handleSubmit}
                        >
                          Submit
                          <div className="nk-spinner"></div>
                        </button>
                        {/* <button
                          className="button_more"
                          //onClick={handleSubmit}
                          onClick={e => {
                            e.preventDefault()
                            handleFormSubmit("faketok")
                          }}
                        >
                          Submit
                        </button> */}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuerySucc = graphql`
  query SuccQuery {
    allMysqlSites {
      edges {
        node {
          free_snapchat
          site_name
          is_active
          site_url
          mysqlId
          sitestat {
            likes
            videos
          }
        }
      }
    }
  }
`

export default ContactUs
